import { Button } from 'react-bootstrap';

export const CustomButton = ({ label, style, href }) => {
	return (
		<div style={{ marginBottom: '10px' }}>
			<a
				href={href}
				style={{
					width: '100%',
					textDecoration: 'none',
					fontWeight: '600',
					color: '#eee',
				}}
			>
				<div
					style={{
						width: '100%',
						padding: '6px',
						backgroundColor: 'rgba(0, 0, 0, 0.6)',
						borderRadius: '5px',
						border: '3px solid black',
					}}
				>
					{label}
				</div>
			</a>
		</div>
	);
};
