export const Footer = () => {
    return (
        <div style={{ fontSize: '20px', padding: "25px 0 40px 0" }}>
            <p>
                <a href='/' style={{ textDecoration: 'none', color: "rgb(0, 0, 0)" }}>
                    https://jschnettler.com
                </a>
            </p>

            <p>
                Last updated on 6/11/2024
            </p>
        </div>
    );
};