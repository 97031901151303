import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Config } from './config';

import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import { LandingPage, ResumePage } from './pages';

const root = ReactDOM.createRoot(document.getElementById('root'));

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant',
		});
	}, [pathname]);

	return null;
};

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ScrollToTop />

			<Routes>
				<Route path='/' element={<LandingPage />} />

				<Route path='/resume' element={<ResumePage />} />

				{[...Config.projects, ...Config.hs_projects].map(
					(project) =>
						!project.exclude && (
							<Route
								path={'/projects' + project.route}
								element={<project.page />}
							/>
						)
				)}

				{Config.code_jams.map(
					(project) =>
						!project.exclude && (
							<Route
								path={'/code-jams' + project.route}
								element={<project.page />}
							/>
						)
				)}
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
