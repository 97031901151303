import {
	AdrenWorldPage,
	AdvancedJavaPage,
	ItemTrackerPage,
	SecuritySystemPage,
	BPSoftwarePage,
	CoursesPage,
	APComputerSciencePage,
	APComputerSciencePrincipalsPage,
	CodingJamPage,
	BedrockBackupsWriteup,
	AutoMatedPlantSystemWriteup,
	PersonalConsoleWriteup,
} from './pages'

import { Container } from 'react-bootstrap'

const Projects = [
	{
		name: 'Breaking Point Software',
		route: '/breaking-point',
		page: BPSoftwarePage,
	},
	// {
	// 	name: 'Automated Plant Growth System Write-up',
	// 	route: '/automated-plant-growth-system-writeup',
	// 	page: AutoMatedPlantSystemWriteup,
	// },
	// {
	// 	name: 'Personal Console System Write-up',
	// 	route: '/personal-console-system-writeup',
	// 	page: PersonalConsoleWriteup,
	// },
	// {
	// 	name: 'Minecraft Bedrock Server Backups Tool Write-up',
	// 	route: '/minecraft-bedrock-server-backups-tool-writeup',
	// 	page: BedrockBackupsWriteup,
	// },
]

const HSProjects = [
	{
		name: 'AP CS Advanced 2023-2024',
		route: '/ap-cs-a',
		page: APComputerSciencePage,
	},
	{
		name: 'AP CS Principles 2023-2024',
		route: '/ap-cs-principals',
		page: APComputerSciencePrincipalsPage,
	},
	{
		name: 'Advanced Java 2022-2023',
		route: '/advanced-java',
		page: AdvancedJavaPage,
	},
]

const CodingJams = [
	{
		name: 'ACM Code Jam 2023',
		route: '/acm-2023',
		page: () => (
			<CodingJamPage
				title='ACM Code Jam 2023'
				description='During the 2023 ACM Code Jam our team worked together to solve sample problems provided by the event organizers. It was a great and eye opening experience, using our combined knowledge to solve the problems was very fun and interesting.'
				certificate={{
					label: 'Certificate of Participation',
					location: '/certifications/acm_code_jam.pdf',
				}}
			/>
		),
	},
]

export const Config = {
	avatar: '/picture2.jpg',
	email: 'jacobschnettler13@gmail.com',
	github: 'https://github.com/jacobschnettler',
	youtube: 'https://www.youtube.com/@jschnettler_',
	projects: Projects,
	hs_projects: HSProjects,
	code_jams: CodingJams,
	quote: () => (
		<span style={{ fontSize: '22px', fontStyle: 'italic' }}>
			“It is hard to fail, but it is worse never to have tried to
			succeed.”
			<br /> – Theodore Roosevelt
			<br />
		</span>
	),
}
