import { Spinner } from "react-bootstrap";

export const SpinnerPage = () => {
    return (
        <div className='text-center' style={{ paddingTop: '85px' }}>
            <Spinner
                animation='border'
                role='status'
                style={{
                    height: '125px',
                    width: '125px',
                    fontSize: '25px',
                }}
            >
                <span className='visually-hidden'>Loading...</span>
            </Spinner>

            <p
                style={{
                    paddingTop: '20px',
                    fontStyle: 'italic',
                    fontSize: '30px',
                    fontWeight: '700',
                }}
            >
                Fetching Projects...
            </p>
        </div>
    );
};