import { Container } from "react-bootstrap";
import { Footer } from "../../components";

export const CodingJamPage = ({ title, description, certificate }) => {
    return (
        <Container
            style={{ paddingTop: '50px', paddingBottom: '30px' }}
        >
            <h1 className='h2 text-center'>{title}</h1>

            {certificate && <p className="h4 pt-3 text-center">
                <a href={certificate.location} target="_blank">
                    {certificate.label}
                </a>
            </p>}

            <p
                style={{ fontSize: '22px', width: '80%', margin: 'auto' }}
                className="text-center pt-3"
            >
                {description}
            </p>

            <div style={{ width: "70%", margin: 'auto' }}>
                <div
                    // style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    className="row pt-3"
                >
                    <img className="col-md-6" src='/pictures/acm_1.jpg' style={{ paddingBottom: '25px' }} />
                    <img className="col-md-6" src='/pictures/acm_2.jpg' style={{ paddingBottom: '25px' }} />
                </div>
            </div>
        </Container>
    );
};